.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #333;
  overflow: hidden;
}
 
.loader-container h1{
  color: #888;
}

.door {
  position: absolute;
  width: 50vw;
  height: 100vh;
  background-color: #333;
  transition: transform 2s ease-in-out;
}

.door-left {
  left: 0;
  transform-origin: left;
  animation: openLeft 2s forwards;
}

.door-right {
  right: 0;
  transform-origin: right;
  animation: openRight 2s forwards;
}

@keyframes openLeft {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(-90deg); }
}

@keyframes openRight {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(90deg); }
}

.loader-text {
  font-size: 3rem;
  color: #fff;
  z-index: 1;
  font-weight: bold;
  animation: fadeOut 1s 1.8s forwards;
}

@keyframes fadeOut {
  to { opacity: 0; }
}

.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    text-align: start;
    padding: 10px;
  }
  
  .carousel-item img {
    width: 100%;
    max-height: 200px;
  }
  
  .carousel-item p {
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
  }

  .price{
    color: var(--link);
  }
  
  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
  }
  
  .carousel-btn.prev {
    left: 10px;
  }
  
  .carousel-btn.next {
    right: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .carousel-item {
      flex: 0 0 50%; /* 2 items per slide */
    }
  }
  
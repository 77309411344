.testimony-container {
    position: relative;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    padding: 20px 0;
    text-align: center;
  }
  
  .testimony-cards::-webkit-scrollbar{
    width: 10px;
  }
  
  .testimony-cards {
    display: flex;
    overflow-x: auto;
    align-items: center;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: 20px;
    height: 500px;
  }
  .testimony-card {
    max-width: 40%;
    height: 300px;
    text-align: start;
    background-color: rgb(232, 234, 234);
    margin: 0 10px;
    border-radius: 10px;
    padding: 20px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .testimony-card:hover{
    background: var(--link);
  }
  
  
  .img-container{
    display: flex;
  }
  .testimony-image{
    width: 80px;
    height: 170px;
    margin-top: -90px;
    margin-right: 20px;
    background: var(--link);
    z-index: 34;
    position: relative;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--link);
    
  }
  
  .testimony-image img{
    width: 50px;
    height: 50px;
    margin-top: 10px;
    border-radius: 50%;
  }
  .testimony-image span{
    margin-top: 30px;
    font-weight: 400;
    font-size: 40px;
    color: white;
    font-family: 'roboto', 'san-serif';
  }
  .testimony-card:hover .testimony-text, .testimony-card:hover .test-p,
  .testimony-card:hover .testimony-name{
    color: #fff;
  }
  .testimony-card:hover .testimony-image, .testimony-card:hover .testimony-image span {
    background: white;
    color: var(--link);
  }
  
  .test-p{
    font-size: 14px;
    color: var(--link);
    margin-top: 0;
  }
  
  .testimony-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    position: relative;
    text-align: start;
    font-family: "Playfair Display", serif;
  } 
  .testimony-text::before{
    font-size: 3.5rem;
    content: " '' ";
    margin-left: -20px;
    color: var(--link);
    
  }
  
  .testimony-text::after{
    font-size: 3.2rem;
    content: " '' ";
    margin-left: 50px;
    color: var(--link);
    top: 92%;
    position: absolute;
    right: 5px;
  }
  
  .testimony-name {
    font-size: 1.2rem;
    font-weight: bold;
  
    color: var(--headings);
  }
  .testimony-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .scroll-button {
    background: var(--link);
    color: #fff;
    width:50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    margin:10px 20px;
    cursor: pointer;
    font-size: 18px;
    transition: background 0.3s ease;
  }
  
  .scroll-button:hover {
    background: #000;
  }
  @media screen and (max-width: 768px){
    .testimony-container{
      width: 100%;
    }
    
    .testimony-card {
      max-width: 80%;
    }
  }
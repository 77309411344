.products-page {
    width: 100%;
    background: #cac8c8;
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .an-title {
    font-size: 1.5rem;
    margin: 40px 20px;
    color: #333;
    animation: slideIn 1s ease-in-out;
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
    width: 70%;
    justify-content: center;
    align-items: center;
  }
  
  .product-card {
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    animation: fadeInUp 1s ease-in-out;
    height: 300px;
    position: relative;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    border-bottom: 0.3px solid #ddd;
  }

  .det{
    width: 100%;
    bottom: 0;
  }
  
  .product-name {
    font-size: 0.9rem;
    color: #333;
    margin: 10px;
  }
  
  .product-price {
    font-size: 0.8rem;
    color: var(--link);
    font-weight: bold;
  }
  

  .product-card:hover .over{
    opacity: 1;
  }

  /* Animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .products-page h1{
    padding: 10px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-btn {
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .pagination-btn:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .pagination-btn.active {
    background: var(--link);
    color: white;
  }
  
  .pagination-btn:hover {
    background-color: #ddd;
  }

  @media screen and (max-width: 768px){
    .products-grid{
      width: 95%;
    grid-template-columns: repeat(2, 1fr);
    }
  }
  
  
:root {
  --family-head: 'Playfair Display', serif;
  --family-head2: 'Abril Fatface', serif;
  --body: 1rem;
  --body-weight: 400;
  --text: #5d666f;
  --link: #ff9100;
  --bg: #f7f7f7;
  --ye: #dce8f3  ;
  --head: #00255c;
  --foot: #04204b;
  --light-bg: #f4f5f8;
  --light-text: #d0d0d4;
  --col: #007bff;
  --mobile-menu: var(--theme-color2);
  --grad: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
}

body{
  font-family: 'Poppins', sans-serif;
  font-family: Arimo, sans-serif;
  line-height: 1.5rem;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.det, .contact-page,.about-hero, .single-product-hero, .about-mis {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.over{
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

a{
  cursor: pointer;
  text-decoration: none;
}

.scroll-to-top, .watsap {
  position: fixed;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  z-index: 2000;
}

.scroll-to-top {
  bottom: 70px;
  right: 5px;
  background: var(--link);
  border: 1px solid white;
  border: none;
  border-radius: 50%;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0.9;
}

.scroll-to-top:hover {
  opacity: 1;
}

.watsap{
  bottom: 10px;
  right: 5px;
  box-shadow: 1px 5px 10px rgba(0,0,0,0.5);
  border-radius: 5%;
  background: rgb(37, 177, 37);
}


.about-hero, .single-product-hero {
  position: relative;
  width: 100%;
  height: 40vh;
  background:  no-repeat center center/cover;
  overflow: hidden;
}

.about-hero{
  background: url(../public/images/aa.png)  no-repeat center center/cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 2s ease-in-out;
}

.hero-heading {
  font-family: var(--family-head2);
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin:0 auto;
  width: 90%;
  letter-spacing: 2px;
  color: #fff;
  border-bottom: 0.3px solid var(--link);
  animation: slideInLeft 0.8s ease-in-out;
}

.hero-subheading {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0 auto;
  width: 60%;
  color: var(--light-text); /* Orange color */
}

.btn3,.back-link, .back-link a, .back-link span{
  color: #fff;
  margin:2px 5px;
  text-align: center;
}


.abbout-title {
  font-size: 1rem;
  color: var(--link); 
  margin: 10px 30px;
  letter-spacing: 1px;
  text-align: left;
}

.abbout-heading {
  font-size: 1.8rem;
  text-align: left;
  color: #5d666f;
  line-height: 1.5;
  margin: 10px 30px;
}

.desc{
  font-size: 1rem;
  color: #87898b;
  margin:10px 30px;
  text-align: left;
}

.admin-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    background-color: #f4f4f4; /* Light background color */
    padding: 20px;
}

.admin-login h1 {
    margin-bottom: 20px;
    font-size: 2rem; /* Larger font for the heading */
    color: #333; /* Darker color for better contrast */
}

.admin-login form {
    background: white; /* White background for the form */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px; /* Padding inside the form */
    width: 300px; /* Fixed width for the form */
}

.admin-login label {
    display: block; /* Block display for labels */
    margin-bottom: 5px; /* Spacing between label and input */
    font-weight: bold; /* Bold labels */
}

.admin-login input {
    width: 100%; /* Full width for inputs */
    padding: 10px; /* Padding inside inputs */
    margin-bottom: 15px; /* Space between inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Standard font size */
}

.admin-login input:focus {
    border-color: #007bff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

.admin-login .error {
    color: red; /* Red color for error messages */
    margin-bottom: 15px; /* Space below error message */
}

.admin-login button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px; /* Padding inside the button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1rem; /* Standard font size */
}

.admin-login button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Services container */
.services-container {
    padding: 1rem;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  .services-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  /* Services grid */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  /* Service box styles */
  .service-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    cursor: pointer;
  }
  
  /* Hover effect */
  .service-box:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon style */
  .icon {
    font-size: 3rem;
    color: #4a90e2;
    margin-bottom: 1.5rem;
  }
  
  /* Service title */
  .service-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  /* Service description */
  .service-description {
    font-size: 1rem;
    color: #555;
  }
  
  /* Animation for service boxes */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply animation to each service box */
  .service-box {
    animation: fadeInUp 0.6s ease-in-out;
  }
  
  /* Responsive grid layout */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
  
    .service-box {
      padding: 1.5rem;
    }
  }
  
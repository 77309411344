
.admin-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.admin-dashboard h1, h2 {
  color: #333;
  text-align: center;
}

.admin-dashboard  .admin-box{
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin-dashboard input[type="text"], 
.admin-dashboard input[type="number"], 
.admin-dashboard textarea, 
.admin-dashboard input[type="file"], 
.admin-dashboard button {
  display: block;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.admin-dashboard textarea {
  resize: vertical;
  min-height: 100px;
}

.admin-dashboard button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.admin-dashboard button:hover {
  background-color: #0056b3;
}

/* Flexbox for lists */
.admin-dashboard ul {
  list-style: none;
  padding: 0;
}

.admin-dashboard ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fafafa;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-dashboard  ul li img {
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.admin-dashboard ul li button {
  flex: 0 0 auto;
  width: auto;
  margin-left: 10px;
}

/* Form sections */
.admin-dashboard div {
  margin-bottom: 30px;
}

.admin-dashboard h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Responsive layout using media queries */
@media (min-width: 768px) {
  .admin-dashboard {
    padding: 40px 0;
  }

  .admin-dashboard input[type="text"], 
  .admin-dashboard input[type="number"], 
  .admin-dashboard textarea, 
  .admin-dashboard input[type="file"], 
  .admin-dashboard button {
    max-width: 500px;
  }

  .admin-dashboard ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .admin-dashboard ul li {
    flex: 1 1 calc(50% - 20px);
    margin: 10px;
    text-align: left;
  }

  .admin-dashboard ul li img {
    max-width: 75px;
    max-height: 75px;
  }
}

@media (min-width: 992px) {
  .admin-dashboard ul li {
    flex: 1 1 calc(33.3333% - 20px);
  }

  .admin-dashboard input[type="text"], 
  .admin-dashboard input[type="number"], 
  .admin-dashboard textarea, 
  .admin-dashboard input[type="file"], 
  .admin-dashboard button {
    max-width: 600px;
  }
}

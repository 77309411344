.contact-page {
    padding: 0;
    background: #f9f9f9;
    width: 100%;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .animated-title {
    font-size: 2.5rem;
    color: #333;
    animation: slideIn 1s ease-in-out;
  }
  
  .animated-subtitle {
    font-size: 1.2rem;
    color: #777;
    animation: fadeIn 2s ease-in-out;
  }
  
  .contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
  }
  .contact-box-map-and-input{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .contact-form-container {
    width: 40%;
    margin: 20px;
  }
  
  .contact-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    animation: slideUp 1.5s ease-in-out;
  }
  .animated-input,
  .animated-textarea {
    width: 100%;
    margin: 10px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    animation: fadeInRight 1s ease-in-out;
  }
  
  .animated-textarea {
    min-height: 100px;
  }
  
  .submit-btn {
    padding: 15px;
    border: none;
    width: 80%;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    animation: pulse 2s infinite;
  }
  
  .contact-map {
    width: 50%;
    text-align: center;
  }
  
  .contact-map h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .map {
    width: 100%;
    height: 350px;
    border: 0;
    border-radius: 10px;
  }
  .contact-details,
.working-hours,
.social-media-links,
.customer-support {
  margin: 40px;
  text-align: center;
}

.contact-details p,
.working-hours p {
  margin: 5px 0;
  color: #555;
}

.social-media-links .social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icons a {
  color: #007bff;
  font-size: 1.5rem;
}

.support-btn {
  background-color: #28a745;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.support-btn:hover {
  background-color: #218838;
}

  
  @media (max-width: 768px) {
    .contact-box-map-and-input{
      flex-direction: column;
      width: 100%;
    }

    .contact-form-container, .contact-map{
      width: 85%;
    }
  
    .animated-subtitle {
      font-size: 1.5rem;
    }
  }
  
  /* Animations */
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
/* Base Styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  /* Container Styling */
  .get-started-container {
    color: #333;
  }
  
  /* Hero Section */
  .get-started-hero {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
                url('/public/images/back.png') center / cover no-repeat;
    color: #fff;
    text-align: center;
    padding: 80px 20px;
    animation: fadeIn 1s ease-out forwards;
  }
  
  .get-started-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .get-started-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    padding: 12px 24px;
    background-color: #FF5722;
    color: #fff;
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  .cta-button:hover {
    transform: scale(1.1);
  }
  
  /* Section Styling */
  section {
    padding: 60px 20px;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .section-intro {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #777;
  }
  
  /* Services Section */
  .services-section {
    background: #f9f9f9;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .service-item {
    width: 220px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: translateY(-10px);
  }
  
  /* Process Section */
  .process-section {
    background: #e8f5e9;
  }
  
  .process-steps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .process-step {
    width: 220px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .process-step:hover {
    transform: translateY(-10px);
  }
  
  /* Testimonials Section */
  .testimonials-section {
    background: #fafafa;
  }
  
  .testimonial {
    max-width: 600px;
    margin: 0 auto 15px;
    font-style: italic;
    color: #555;
  }
  
  /* Contact Section */
  .contact-section {
    background: #e0f7fa;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-form input, 
  .contact-form textarea {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .form-button {
    padding: 10px 20px;
    background-color: #009688;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .form-button:hover {
    background-color: #00796b;
  }
  
  /* Keyframe Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
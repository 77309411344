@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.he-container {
    position: relative;
    width: 100%;
    height: 80vh; 
    background: url("/public/images/tapebg.jpg"); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  /* Dark overlay for text contrast */
  .he-overlay {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }
  
  /* Hero Content */
  .he-content {
    position: relative;
    z-index: 2;
    top: 100px;
    text-align: center;
    color: #fff;
    animation: fadeIn 2s ease-in-out;
    padding: 20px;
  }
  
  /* Animations for Title, Subtitle, and Button */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .he-title {
    font-family: var(--family-head);
    font-size: 3rem;
    padding: 20px;
    margin-bottom: 20px;
    animation: slideIn 2.5s ease-in-out;
  }
  
  .he-subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
    animation: sllideIn 2.8s ease-in-out;
  }
  
  /* Animation for sliding content */
  @keyframes sllideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .he-button{
    background: #333;
    color: white;
    border: 0.5px solid white;
    font-family: 'Playfair Display', serif;
  }

  .he-btn{
    color: white;
    background: var(--link);
    border: none;
    font-family: 'Playfair Display', serif;
  }
  
  .he-btn, .he-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    animation: fadeIn 3s ease-in-out;
  }

  .he-button:hover {
    background-color: #272421;
  }
  
  .he-btn:hover {
    background-color: #cc5200;
  }
  
  @media (max-width: 768px) {
    .he-title {
      font-size: 2rem;
    }
    .he-subtitle {
      font-size: 1.2rem;
    }
  }
  
/* Services.css */
.sservices-container {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .sservices-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .sservices-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .sservice-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 1.5rem;
    border-radius: 8px;
  }

  .sservice-card:hover  {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .head-icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sservice-title, .sservice-icon{
    font-size: 1.75rem;
    color: var(--link);
    margin: 5px;
  }
  
  .service-desc {
    font-size: 1rem;
    color: #888;
  }
  
.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .chat-header {
    background-color: #007bff;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  .chat-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .chat-message {
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .user-message {
    align-self: flex-end;
    background-color: #007bff;
    color: white;
  }
  
  .admin-message {
    align-self: flex-start;
    background-color: #eee;
    color: black;
  }
  
  .chat-footer {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .chat-send-btn {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .chat-send-btn:hover {
    background-color: #0056b3;
  }
  
  .success-alert {
    background-color: #4caf50;  /* Green color */
    color: white;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    transition: opacity 0.5s ease-in-out;
  }
  
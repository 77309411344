
.counter-section {
    margin: 50px 0;
    width: 100%;
    background:linear-gradient(rgba(8, 26, 125, 0.7), rgba(7, 15, 119, 0.8)), url(../../public/images/aa.png);
  }

  .counter-section h1{
    color: #fff;
    font-size: 2rem;
  }
  
  .counter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .counter-item {
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .counter-item h3 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: var(--link);
  }
  
  .counter-item:hover {
    transform: scale(1.05);
  }
  
  .counter-item p {
    font-size: 0.8rem;
    color: #fff;
    padding:10px 40px;
  }
  
  @media screen and (max-width: 786px) {
   
  }
  
/* Footer Styles */
.footer {
    position: relative;
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .footer-section {
    max-width: 300px;
    margin: 10px;
  }
  
  .footer-section h3 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  
  .footer-section p,
  .footer-section a {
    font-size: 1rem;
    line-height: 1.8;
    color: #ccc;
    text-decoration: none;
    margin: 10px;
  }
  
  .footer-section a:hover {
    color: #ff6600;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #fff;
    text-decoration: none;
    border-top: 1px solid #444;
    padding-top: 15px;
  }

  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: left;
    }
  
    .footer-section {
      margin: 10px 0;
    }
  }
  
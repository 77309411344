.ppproducts-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .category-section {
    margin-bottom: 40px;
  }
  
  .category-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  
  .category-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  }
  
  .category-products::-webkit-scrollbar {
    height: 8px;
  }
  
  .category-products::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .ppproduct-card {
    text-align: center;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .ppproduct-card:hover {
    transform: scale(1.05);
  }
  
  .ppproduct-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .ppproduct-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .ppproduct-price {
    font-size: 14px;
    color: #555;
  }
  
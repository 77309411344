/* News Container */
.news-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  background-color: #f0f8ff;
}

/* Title */
.news-title {
  font-size: 3rem;
  color: #ff6347;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  animation: slideDown 1s ease-in-out;
}

/* Grid layout for news articles */
.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 90%;
}

/* Individual News Card */
.news-card {
  background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

/* Hover effect for cards */
.news-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

/* News image */
.news-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #ff6347;
  transition: all 0.3s ease;
}

/* Hover effect for the image */
.news-card:hover .news-image {
  transform: scale(1.05);
}

/* News content */
.news-content {
  padding: 1.5rem;
  text-align: left;
}

.news-content h2 {
  color: #4b0082;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

/* Removed description from card view */
.news-content p {
  display: none; 
}

/* Read more button */
.read-more-btn {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 0.8rem;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  border-top: 2px solid #f0f8ff;
}

.read-more-btn:hover {
  background-color: #ff4500;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  position: relative;
  max-height: 80vh; 
  overflow-y: auto;
  text-align: left; 
}

/* Modal Image */
.modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Modal Description */
.modal-description {
  color: #555;
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 1rem; /* Space between title and description */
}

/* Close Button */
.close-btn {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #ff4500;
}

/* Responsive */
@media (max-width: 768px) {
  .news-title {
    font-size: 2.5rem;
  }

  .news-card {
    margin-bottom: 2rem;
  }
}

/* Animations */
@keyframes slideDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

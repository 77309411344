.home-div{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.home-div img{
    width: 30%;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .home-div{
        flex-direction: column;
    }

    .home-div img{
        width: 80%;
    }
}
/* Overall Page Styling */
.single-product-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #f3f3f3, #eaeaea);
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.single-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.single-p-img{
  width: 50%;
}

.single-details{
  width: 45%;
}

/* Product Image Styling */
.single-product-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transform: scale(0.95);
  transition: transform 0.5s ease-in-out;
}

.single-product-image:hover {
  transform: scale(1); /* Hover effect to enlarge the image */
}
.single-details h2{
  color: #444;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Product Description Styling */
.single-details p {
  font-size: 0.8rem;
  color: #555;
  line-height: 1.6;
  text-align: left;
  max-width: 800px;
  margin-bottom: 20px;
  padding: 0 20px;
  animation: slideInRight 1s ease-in-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Product Price Styling */
.single-div span {
  font-size: 2rem;
  color: var(--link);
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
  animation: popIn 1.2s ease-in-out;
}

@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.single-link{
  margin: 40px 0;
  text-align: left;
}

.link-watsap, .link-call{
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
}

.link-watsap{
  background: rgb(28, 187, 28);
}
.link-call{
  background: #000;
}

.choose{
  color: #666;
  font-family: 'Amatic SC', cursive;
  text-decoration: underline;
  padding: 5px;
  width: 85%;
}

.single-details .product-features {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.single-details .product-features h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.single-details .product-features ul {
  list-style-type: disc;
  padding-left: 20px;
}

.single-details .product-features li {
  font-size: 0.9rem;
  color: #666;
  list-style: square;
  margin-bottom: 8px;
  text-align: left;
}

.single-details .product-features li:last-child {
  margin-bottom: 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  .single-div{
    flex-direction: column;
  }

  .single-product-page h1 {
    font-size: 2rem;
  }
  
  .single-product-page p {
    font-size: 1rem;
    padding: 0 10px;
  }

  .single-product-image {
    max-width: 100%;
  }

  .single-div span {
    font-size: 1.5rem;
  }

  .single-p-img{
    width: 90%;
  }
  
  .single-details{
    width: 90%;
  }
}

/* Navbar Container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #333;
    color: #fff;
    position: relative;
    z-index: 1003;
    position: sticky;
  }
  
  .logo img{
    width: 80px;
  }
  
  /* Default Links (for large screens) */
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links .navs {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    padding:0 5px;
  }
  
  .nav-links a:hover {
    color: #ffb300;
    background: none;
  }
  
  /* Hamburger Icon */
  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    color: #fff;
  }
  
  /* Hamburger Open and Close Icon */
  .hamburger-icon {
    font-size: 2rem;
  }
  
  .close-icon {
    font-size: 2.5rem;
    color: #ffb300;
  }
    /* Blinking arrow */
.arrow ,.navs span{
    display: none;
}
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .nav-links {
      position: fixed;
      top: 0;
      right: -100%;
      height: 100vh;
      width: 300px;
      background-color: #333;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      transition: all 0.4s ease-in-out;
    }
  
    .nav-links.open {
      right: 0;
    }
  
    .hamburger {
      display: block;
      position: fixed;right: 12px;
      z-index: 1003;
      background: rgba(51, 51, 51, 0.7);
      padding: 0 5px;
      border-radius: 5px;
    }
  
    .nav-links .navs {
      font-size: 1.3rem;
      padding:5px 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .navs span{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border:0.3px solid #ffb300;
      border-radius: 3px;
    }

    .nav-links a:hover {
      background: #4a4a4a;
    }

    .arrow {
        position: fixed;
        top: 33px;
        right: 60px;
        border: solid rgb(255, 255, 255);
        border-width: 0 5px 5px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
        animation: blink-animation 1.8s infinite;
      }
      
      @keyframes blink-animation {
        50% {
          opacity: 0;
        }
      }
  }
  
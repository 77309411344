/* General Page Styling */
.about-page {
  font-family: 'Arial', sans-serif;
  width: 100%;
  color: #333;
  animation: fadeIn 1.2s ease-in-out;
}

/* Typography */
.about-page h2, .about-page h3, .about-page h4 {
  color: #333;
  text-align: center;
}

/* About Section */
.about-section {
  margin-bottom: 40px;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  padding: 50px 20px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #333;
  color: #fff;
  animation: fadeInUp 1s ease-in-out;
}

/* Team Section */
.team-section {
  margin-bottom: 40px;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member {
  width: 380px;
  margin-bottom: 30px;
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
}

.team-member:hover {
  transform: translateY(-10px);
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 4px solid #ff6600;
}

.team-member-name {
  font-size: 1.3rem;
  color: #333;
}

.team-member-role {
  font-size: 1.1rem;
  color: #ff6600;
  margin-bottom: 10px;
}

.team-member-description {
  font-size: 1rem;
  color: #555;
}

/* Vision & Mission Section */
.vision-mission-section {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.vision-mission{
  display: flex;
  flex-wrap: wrap;
}

.vision-description,
.mission-description {
  font-size: 1.1rem;
  max-width: 400px;
  height: 200px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.5rem;
  background: #e0f7fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.vision-description{
  background: #e0f7fa;
}
.mission-description{
  background-color: #ffe5b4;
}

/* Values Section */
.values-section {
  margin-bottom: 40px;
}

.values-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-print{
  width: 45%;

}

.about-print img{
  width: 100%;
}

.about-mis{
  width: 45%;
}

.value-item {
  width: 280px;
  margin: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1.5s ease-in-out;
}

.value-icon {
  display: inline-block;
  font-size: 30px;
  margin-right: 10px;
}

.value-title {
  font-size: 1.2rem;
  color: #ff6600;
}

.value-description {
  font-size: 0.9rem;
  color: #555;
}

/* Companies Section */
.companies-section {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.companies-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 60%;
}

.company-item {
  width: 100px;
  margin: 10px;
  background-color: #ff6600;
  color: #fff;
  border-radius: 8px;
  font-size: 1.2rem;
  animation: slideIn 1.2s ease-in-out;
}

.company-item img {
  width: 100%;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  0% { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; transform: translateX(0); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .team-container {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    width: 90%;
  }

  .about-description {
    font-size: 1.1rem;
    margin: 0 25px;
  }

  .companies-list {
    width: 100%;
  }

  .company-item {
    width: 20%;
    margin-bottom: 10px;
  }

  .vision-mission-section {
    flex-direction: column;
  }

  .values-container {
    flex-direction: column;
    align-items: center;
  }

  .about-print{
    width: 85%;
  
  }
  
  .about-mis{
    width: 85%;
  }

  .value-item {
    width: 90%;
    margin-bottom: 20px;
  }
}
